<template>
  <div id="resetrequest">

    <div class="header">
      <h1>Passwort vergessen?</h1>
      <p>Gib deine E-Mail-Adresse oder deinen Discord Namen ein, damit wir dir einen Link senden können, mit dem du dein
        Passwort zurücksetzen kannst.</p>
    </div>
    <div>
      <TextInput do-validation :validation-function="validFunction" input-name="e-mail-discord"
                 input-title="E-mail oder Discord Name"
                 input-placeholder="user@email.com oder User#1234"
                 v-model:input-text="inputText"
                 text-field-type="email" :key="'e-mail-discord-field'"
                 :ref="(el)=>setRequestRefs(el,'e-mail-discord-field')" class="textInput"
      >
      </TextInput>
      <PlainButton :button-function="()=>submitRequest()">Link Senden</PlainButton>
    </div>

    <transition name="error-show">
      <ErrorPop :error-text="errorMessage" v-if="errorType==='alert-danger'">
      </ErrorPop>
    </transition>
    <transition name="error-show">
      <SuccessPop :error-text="errorMessage" v-if="errorType==='alert-success'">
      </SuccessPop>
    </transition>
  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput.vue";
import PlainButton from "@/components/Buttons/PlainButton.vue";
import {userService} from "@/services";
import {mapActions, mapState} from "vuex";
import ErrorPop from "@/components/Popups/ErrorPop.vue";
import SuccessPop from "@/components/Popups/SuccessPop.vue";

export default {
  name: "PasswordResetRequest",
  components: {SuccessPop, ErrorPop, PlainButton, TextInput},
  inheritAttrs: false,


  computed: {
    ...mapState('alert', {
      errorMessage: 'message',
      errorType: 'type'
    })
  },

  data() {
    return {
      inputText: "",
      refs: {},
    }
  },

  methods: {
    validFunction(text) {

      if (text.length === 0) {
        return {
          isInvalid: true,
          errorText: 'Email oder Discord Namen eingeben'
        }

      }else if(!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text)&&!/^([^@#`]){2,32}#[0-9]{4}$/.test(text)){
        return {
          isInvalid: true,
          errorText: 'Keine E-Mail oder Discord Name'
        }

      }else {
        return {isInvalid: false, errorText: ''}
      }
    },
    ...mapActions('alert', ['clear']),
    async submitRequest() {
      if (this.validateTexts()) {
        await this.clear();
        await userService.sendPasswordResetRequest(this.inputText);
      }
    },
    setRequestRefs(el, name) {
      if (el) {
        this.refs[name] = el;
      }
    },
    validateTexts() {
      let isValid = true;
      this.refs['e-mail-discord-field'].validate(this.inputText)
      isValid = !this.refs['e-mail-discord-field'].isInvalid && isValid;
      return isValid;
    }


  }


}
</script>

<style scoped lang="scss">

@import "src/styles/themes.scss";

#resetrequest {

  padding: 0 32px;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  min-height: max-content;
  flex-direction: column;
}

.header {
  max-width: 60%;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 4em;
  line-height: 1em;
  @include theme() {
    color: theme-get('header-1')
  }
  margin: 0;
}

p {
  font-size: 1.5rem;
  @include theme() {
    color: theme-get('text-2')
  }
}

.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}


.textInput {
  margin-bottom: 48px;
}


@media screen and (max-width: 768px) {
  .header {
    max-width: none;
  }
  h1 {
    font-size: 3em;
  }
  p {
    font-size: 1.25em;
  }
}

@media screen and (max-width: 480px) {

  h1 {
    font-size: 2em;
  }
  p {
    font-size: 1em;
  }
}


</style>
